// Korean

jQuery.extend( jQuery.fn.pickadate.defaults, {
  monthsFull: [ '1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월' ],
  monthsShort: [ '1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월' ],
  weekdaysFull: [ '일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일' ],
  weekdaysShort: [ '일', '월', '화', '수', '목', '금', '토' ],
  today: '오늘',
  clear: '취소',
  firstDay: 1,
  format: 'yyyy-mm-dd',
  formatSubmit: 'yyyy-mm-dd',
  hiddenPrefix: 'date',
  formatSubmit: 'yyyy-mm-dd'
});

jQuery.extend( jQuery.fn.pickatime.defaults, {
  clear: '취소',
  hiddenPrefix: 'time'
});
