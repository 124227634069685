G.$form_$to_attrs = function($parent) {
  return _p.reduce($.find($parent, '[name]'), function(obj, input) {
    if (input.type == 'checkbox') return obj[input.name] = input.checked, obj || void 0;
    return input.type == "radio" && !input.checked
      ? obj : obj[input.name] = function(v) {
        return $.attr(input, 'don_number') ? parseFloat(v) : (typeof v == 'string' ? v.trim() : v);
      }($.val(input)), obj;
  }, {});
};

G.$notice_item_$close = __(
  $.remove_class('selected'),
  $.find('.detail'),
  $.remove
);

G.$this_targets = function(e) {
  this.$currentTarget = this.$cTarget = e.$currentTarget;
  this.$delegateTarget = this.$dTarget = e.$delegateTarget;
  this.target = e.target;
  return e;
};

G.update_query_str = function(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var splited = uri.split('#');
  uri = splited[0];
  var hash = splited[1] ? '#' + splited[1] : '';
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  return (uri.match(re) ? uri.replace(re, '$1' + key + "=" + value + '$2') : uri + separator + key + "=" + value) + hash;
};

G.query_str = function(search) {
  if (!search) return {};
  return search.split('#')[0].replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
};

G.is_mobile_size = function() {
  return $.width($1('html')) < 1025;
};

G.is_pc_size = function() {
  return !G.is_mobile_size();
};

G.copy_clipboard = function(val) {
  var t = document.createElement("textarea");
  document.body.appendChild(t);
  t.value = val;
  t.select();
  document.execCommand('copy');
  document.body.removeChild(t);
};

G.change_title = function(val) {
  $.text($1('title'), 'MARPPLE' + (val ? (' - ' + val) : ''));
  // $.text($1('#m_title'), val); 한글버전
};

G.change_window = function(func) {
  if (window.is_ie) return ;
  var is_focus_out = false;
  _p.go(window,
    $.on('focus', function() {
      if (!is_focus_out) return ;
      is_focus_out = false;
      if (func) return func();
      window.location.reload();
    }),
    $.on('blur', function() { is_focus_out = true; }));
};
