!function (window) {
  var util = window.util = window.util || {};

  util.validation = util.validation || {};
  util.validation.is_mobile = function (target) {
    var regMobile = /[01](0|1|6|7|8|9)[-_.]?(\d{4}|\d{3})[-_.]?\d{4}$/g;
    return regMobile.test(target);
  };

  util.validation.is_valid_email = function (val) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
    // var reg = /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{2,5}$/;
    // return reg.test(target);
  };

  util.validation.is_input_empty = function(val) {
    return val.replace(/^(\s*)|(\s*$)/gi, "") == "";
  };

  util.validation.is_input_password = function(val) {
    var pattern1 = /[0-9]{1,}/g;
    var pattern2 = /[a-zA-Z]{1,}/g;
    var pattern3 = /^[\w]{4,16}$/g;
    var result = false;

    if (pattern1.test(val)) { result = true; }
    else { return false; }

    if (pattern2.test(val)) { result = true; }
    else { return false; }

    if (pattern3.test(val)) { result = true; }
    else { return false; }

    return result;
  };

  util.validation.is_input_character = function(val) {
    var regEmail = /^[0-9a-zA-Z가-힣]+$/gi;
    return regEmail.test(val);
  };

  util.validation.is_input_email = function(val) {
    var regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[@][0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[.][a-zA-Z]{2,3}$/gi;
    return regEmail.test(val);
  };

  util.validation.is_input_number = function(val) {
    var regSort = /^\d+$/g;
    return regSort.test(val);
  };

  util.validation.pw_validation_chk = function(pwd) {
    var regPwd = /^.*(?=^.{4,16}$)(?=.*\d)(?=.*[a-zA-Z]).*$/;
    if (!regPwd.test(pwd)) return false;
    else return true;
  };

  util.validation.is_input_date = function(year_val, month_val, date_val) {
    var thisYear = new Date().getFullYear()
      , thirtyOne = [1,3,5,7,8,10,12]
      , thirty = [4,6,9,11]
      , fab = 28
      , year = parseInt(year_val, 10)
      , month = parseInt(month_val, 10)
      , date = parseInt(date_val, 10);

    if ((year%4 == 0 && year%100 != 0) || year%400 == 0) {
      fab = 29;
    }

    if (!(year > 1900 && year <= thisYear)) return false;
    if (!(month > 0 && month <= 12)) return false;

    if (thirtyOne.indexOf(month) >= 0 && (0 < date && date <= 31)) return true;
    else if (thirty.indexOf(month) >= 0 && (0 < date && date <= 30)) return true;
    else if (month == '2' && (0 < date && date <= fab)) return true;

    return false;
  };

  util.nl_to_br = function(str) {
    return str && str.replace && str.replace(/(\n|\r\n)/g, '<br>');
  };

  util.to_inch_str = function(cm) {
    var inch = (cm / 2.54).toFixed(1);
    return inch.substr(inch.length-2, inch.length-1) == '.0' ?
      inch.substr(0, inch.length-2) : inch;
  };

  util.only_number = function(number) {
    return number.replace(/[^0-9]/g,'');
  };

  util.check_phone_number = function(number) {
    var chk_number = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
    return chk_number.test(number);
  };

  util.isValidRegistrationCodeKr = function (v) {
    var checkNumber = /^\d{6}-\d{7}$/;
    return checkNumber.test(v);
  };

  // 사업자 등록번호 형식 검증
  util.isValidBusinessRegistrationNumber = function(v) {
    var checkNumber = /^\d{3}-\d{2}-\d{5}$/;
    return checkNumber.test(v);
  };

} (typeof window == 'undefined' ? global : window);
